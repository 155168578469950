import { render, staticRenderFns } from "./Dados.vue?vue&type=template&id=318d4a44&scoped=true"
import script from "./Dados.vue?vue&type=script&lang=js"
export * from "./Dados.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318d4a44",
  null
  
)

export default component.exports